import React, { useEffect, useState } from 'react';

const useDefaultLanguage = () => {
    // 获取当前的系统语言并设置初始值
    const [defaultLanguage, setDefaultLanguage] = useState(navigator.language.includes('zh') ? 'zh' : 'en');

    useEffect(() => {
        const handleLanguageChange = () => {
            const newLanguage = navigator.language.includes('zh') ? 'zh' : 'en';
            setDefaultLanguage(newLanguage);
        };

        // 监听语言变化事件
        window.addEventListener('languagechange', handleLanguageChange);

        // 清除监听器
        return () => {
            window.removeEventListener('languagechange', handleLanguageChange);
        };
    }, []);

    return defaultLanguage;
};

export default useDefaultLanguage;
