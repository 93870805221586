import React, {useContext, useEffect} from 'react';
import {Button, Col, DatePicker, Divider, Form, Input, Row, Space, Tooltip} from "antd";
import UploadFileBox from "@/components/common/dragUpload";
import {moveItem, StepContext} from "@/components/quickfilingComp/dashboardProfile/profileForm";
import {PlusOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import Title from "antd/es/typography/Title";
import {
    setAwardFundingReviewerExperiences,
    setConsultingExperiences,
    setProfessionalMemberships,
    setReviewerExperiences, setWorkUsedForCourses
} from "@/store/modules/quick";
import useManageFormList from "@/customHooks/ManagerFormList";
import dayjs from 'dayjs';

const ProfessionalExperiences = () => {

    const {current, setCurrent} = useContext(StepContext)
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const quickFormData = useSelector(state => state.quickForm.quickFilingForm);
    const { addItem, customRemove, handleFieldChange } = useManageFormList(form);

    useEffect(() => {
        form.setFieldsValue({
            reviewerExperiences: quickFormData.reviewerExperiences || [],
            professionalMemberships: quickFormData.professionalMemberships || [],
            awardFundingReviewerExperiences: quickFormData.awardFundingReviewerExperiences || [],
            consultingExperiences: quickFormData.consultingExperiences || [],
            workUsedForCourses: quickFormData.workUsedForCourses || []
        });
    }, [])

    let addReviewerExperience;
    let addProfessionalMembership;
    let addAwardFundingReviewerExperience;
    let addConsultingExperience;
    let addWorkUsedForCourses;

    const onFinish = async () => {
        await setForm2Redux()
        // 跳转到下一步
        setCurrent(current + 1);
    }

    const prev = async () => {
        await setForm2Redux()
        setCurrent(current - 1);
    }

    const setForm2Redux = async () => {
        try {
            // 验证表单数据
            const values = await form.validateFields();
            // 保存到 Redux
            dispatch(setReviewerExperiences(values.reviewerExperiences));
            dispatch(setProfessionalMemberships(values.professionalMemberships));
            dispatch(setAwardFundingReviewerExperiences(values.awardFundingReviewerExperiences));
            dispatch(setConsultingExperiences(values.consultingExperiences));
            dispatch(setWorkUsedForCourses(values.workUsedForCourses));
        } catch (error) {
            console.error('Validation Failed:', error);
        }
    }

    return (
        <div className={'eduEmployForm'}>
            <Form form={form} layout="vertical"
                  onValuesChange={(changedValues, allValues) => handleFieldChange(changedValues, allValues)}
            >
                <div>
                    <Title level={3}>Reviewer & Membership & Consulting & Courses</Title>
                </div>
                <Divider />
                <Title level={4}>Reviewer Experiences</Title>
                <Form.List name="reviewerExperiences">
                    {(fields, {add, remove}) => {
                        addReviewerExperience = add
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => {
                                    const currentStatus = form.getFieldValue(['reviewerExperiences', index, 'operate']);
                                    if (currentStatus === 3) {
                                        return null;
                                    }
                                    return (
                                        <div className={'edu-history'} key={index}>
                                            <Title level={5}>*reviewer experience-{index + 1}</Title>
                                            <Space align={'start'}>
                                                <div>
                                                    <div className={'edu-history-item'}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'journalName']}
                                                                    label='Conference/Journal Name'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'year']}
                                                                    label='Year'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'role']}
                                                                    label={<Tooltip
                                                                        title={'PC member, editor, reviewer, etc.'}>Your
                                                                        Role</Tooltip>}
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'ranking']}
                                                                    label='Ranking of the above Conference/Journal'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Proof of Ranking</Title>
                                                    <UploadFileBox form={form} fieldName={'reviewerExperiences'}
                                                                   index={index} label={'ranking_proof'}/>
                                                    <br/>
                                                    {/*todo*/}
                                                    <Title level={5}>todo -- Proof of Reviewer Experience</Title>

                                                </div>
                                                <Space direction={"vertical"}>
                                                    {fields.length > 1 && <Space direction={"vertical"}>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, -1, fields, 'reviewerExperiences')}>Up</Button>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, 1, fields, 'reviewerExperiences')}>Down</Button>
                                                    </Space>}
                                                    <Button onClick={() => customRemove('reviewerExperiences',remove, name, index)}>Remove</Button>
                                                </Space>
                                            </Space>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                        <Button
                            onClick={() => addReviewerExperience()}
                            icon={<PlusOutlined/>}
                        >
                            Add Reviewer Experience
                        </Button>
                </div>
                <Divider/>
                <Title level={4}>Professional Membership</Title>
                <Form.List name="professionalMemberships">
                    {(fields, {add, remove}) => {
                        addProfessionalMembership = add
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => {
                                    const currentStatus = form.getFieldValue(['professionalMemberships', index, 'operate']);
                                    if (currentStatus === 3) {
                                        return null;
                                    }
                                    return (
                                        <div className={'edu-history'} key={index}>
                                            <Title level={5}>*professional membership-{index + 1}</Title>
                                            <Space align={'start'}>
                                                <div>
                                                    <div className={'edu-history-item'}>
                                                        <Row gutter={16}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'organizationName']}
                                                                    label='Name of Organization'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'website']}
                                                                    label='Official Website'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'intro']}
                                                                    label='Organization Intro'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'membershipType']}
                                                                    label='Membership Type'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'dateOfJoining']}
                                                                    label='Date Of Joining'
                                                                    getValueProps={(value) => ({
                                                                        value: value && dayjs(value, 'YYYY-MM-DD'),
                                                                    })}
                                                                    normalize={(value) => value && dayjs(value).format('YYYY-MM-DD')}
                                                                >
                                                                    <DatePicker showTime={false} format="YYYY-MM-DD"/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Proof</Title>
                                                    <UploadFileBox form={form} fieldName={'professionalMemberships'}
                                                                   index={index} label={'professional_proof'}/>
                                                </div>
                                                <Space direction={"vertical"}>
                                                    {fields.length > 1 && <Space direction={"vertical"}>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, -1, fields, 'professionalMemberships')}>Up</Button>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, 1, fields, 'professionalMemberships')}>Down</Button>
                                                    </Space>}
                                                    <Button onClick={() => customRemove('professionalMemberships',remove, name, index)}>Remove</Button>
                                                </Space>
                                            </Space>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                        <Button
                            onClick={() => addItem('professionalMemberships', addProfessionalMembership)}
                            icon={<PlusOutlined/>}
                        >
                            Add Professional Membership
                        </Button>
                </div>
                <Divider/>
                <Title level={4}>Award & Funding Reviewer Experiences</Title>
                <Form.List name="awardFundingReviewerExperiences">
                    {(fields, {add, remove}) => {
                        addAwardFundingReviewerExperience = add
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => {
                                    const currentStatus = form.getFieldValue(['awardFundingReviewerExperiences', index, 'operate']);
                                    if (currentStatus === 3) {
                                        return null;
                                    }
                                    return (
                                        <div className={'edu-history'} key={index}>
                                            <Title level={5}>*award & funding reviewer-{index + 1}</Title>
                                            <Space align={'start'}>
                                                <div>
                                                    <div className={'edu-history-item'}>
                                                        <Row gutter={16}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'awardFundingName']}
                                                                    label='Award Funding Name'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'aboutAwardFunding']}
                                                                    label={<Tooltip
                                                                        title={'What is this award about, is it competitive? or does the award have big impact in your field?'}>About
                                                                        Award Funding</Tooltip>}
                                                                >
                                                                    <Input.TextArea rows={4}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'dateOfJoining']}
                                                                    label='Date Of Join'
                                                                    getValueProps={(value) => ({
                                                                        value: value && dayjs(value, 'YYYY-MM-DD'),
                                                                    })}
                                                                    normalize={(value) => value && dayjs(value).format('YYYY-MM-DD')}
                                                                >
                                                                    <DatePicker showTime={false} format="YYYY-MM-DD"/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'agencyOrganization']}
                                                                    label={<Tooltip
                                                                        title={'Who created this funding or awards, such as NSF.'}>Agency
                                                                        Organization</Tooltip>}
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'agencyOrganizationIntro']}
                                                                    label='About Agency Organization'
                                                                >
                                                                    <Input.TextArea rows={4}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Award Funding Proof</Title>
                                                    <UploadFileBox form={form}
                                                                   fieldName={'awardFundingReviewerExperiences'}
                                                                   index={index} label={'funding_reviewer_proof'}/>
                                                    <br/>
                                                    <Title level={5}>Agency Organization Proof</Title>
                                                    <UploadFileBox form={form}
                                                                   fieldName={'awardFundingReviewerExperiences'}
                                                                   index={index} label={'org_proof'}/>
                                                </div>
                                                <Space direction={"vertical"}>
                                                    {fields.length > 1 && <Space direction={"vertical"}>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, -1, fields, 'awardFundingReviewerExperiences')}>Up</Button>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, 1, fields, 'awardFundingReviewerExperiences')}>Down</Button>
                                                    </Space>}
                                                    <Button onClick={() => customRemove('awardFundingReviewerExperiences',remove, name, index)}>Remove</Button>
                                                </Space>
                                            </Space>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                        <Button
                            onClick={() => addItem('awardFundingReviewerExperiences', addAwardFundingReviewerExperience)}
                            icon={<PlusOutlined/>}
                        >
                            Add Award & Funding Reviewer Experience
                        </Button>
                </div>
                <Divider/>
                <Title level={4}>Consulting Experience</Title>
                <Form.List name="consultingExperiences">
                    {(fields, {add, remove}) => {
                        addConsultingExperience = add
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => {
                                    const currentStatus = form.getFieldValue(['consultingExperiences', index, 'operate']);
                                    if (currentStatus === 3) {
                                        return null;
                                    }
                                    return (
                                        <div className={'edu-history'} key={index}>
                                            <Title level={5}>*consulting experience-{index + 1}</Title>
                                            <Space align={'start'}>
                                                <div>
                                                    <div className={'edu-history-item'}>
                                                        <Row gutter={16}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'consulteeName']}
                                                                    label={<Tooltip
                                                                        title={'This refers to the person or organization that receives advice or services from a consultant'}>Consultee
                                                                        Name</Tooltip>}
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'aboutConsultee']}
                                                                    label={<Tooltip
                                                                        title={'Short intro about the consultee, focuses on its impact on your fields, e.g., #1 xx provider in xx area'}>About
                                                                        Consultee</Tooltip>}
                                                                >
                                                                    <Input.TextArea rows={4}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'summaryOfConsulting']}
                                                                    label='Summary Of Consulting Service'
                                                                >
                                                                    <Input.TextArea rows={4}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'expertiseNeeded']}
                                                                    label='Expertise Needed'
                                                                >
                                                                    <Input.TextArea rows={4}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Consulting Proof</Title>
                                                    <UploadFileBox form={form} fieldName={'consultingExperiences'}
                                                                   index={index} label={'consulting_proof'}/>
                                                </div>
                                                <Space direction={"vertical"}>
                                                    {fields.length > 1 && <Space direction={"vertical"}>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, -1, fields, 'consultingExperiences')}>Up</Button>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, 1, fields, 'consultingExperiences')}>Down</Button>
                                                    </Space>}
                                                    <Button onClick={() => customRemove('consultingExperiences',remove, name, index)}>Remove</Button>
                                                </Space>
                                            </Space>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                        <Button
                            onClick={() => addItem('consultingExperiences', addConsultingExperience)}
                            icon={<PlusOutlined/>}
                        >
                            Add Consulting Experience
                        </Button>
                </div>
                <Divider/>
                <Title level={4}>Work Used For Courses</Title>
                <Form.List name="workUsedForCourses">
                    {(fields, {add, remove}) => {
                        addWorkUsedForCourses = add;
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => {
                                    const currentStatus = form.getFieldValue(['workUsedForCourses', index, 'operate']);
                                    if (currentStatus === 3) {
                                        return null;
                                    }
                                    return (
                                        <div className={'edu-history'} key={index}>
                                            <Title level={5}>*work used for courses-{index + 1}</Title>
                                            <Space align={'start'}>
                                                <div>
                                                    <div className={'edu-history-item'}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'courseName']}
                                                                    label='Course Name'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'university']}
                                                                    label='University'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'usageDescription']}
                                                                    label={<Tooltip
                                                                        title={'How your work is used in the project'}>Usage
                                                                        Description</Tooltip>}
                                                                >
                                                                    <Input.TextArea rows={4}/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Proof</Title>
                                                    <UploadFileBox form={form} fieldName={'workUsedForCourses'}
                                                                   index={index}
                                                                   label={'courses_proof'}/>
                                                </div>
                                                <Space direction={"vertical"}>
                                                    {fields.length > 1 && <Space direction={"vertical"}>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, -1, fields, 'workUsedForCourses')}>Up</Button>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, 1, fields, 'workUsedForCourses')}>Down</Button>
                                                    </Space>}
                                                    <Button onClick={() => customRemove('workUsedForCourses',remove, name, index)}>Remove</Button>
                                                </Space>
                                            </Space>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                        <Button
                            onClick={() => addItem('workUsedForCourses', addWorkUsedForCourses)}
                            icon={<PlusOutlined/>}
                        >
                            Add Work Used For Courses
                        </Button>
                </div>
                <Divider/>
            </Form>
            <Button style={{margin: '0 8px'}} onClick={prev}>Prev</Button>
            <Button type={"primary"} onClick={onFinish}>Next</Button>
        </div>
    )
}

export default ProfessionalExperiences