

/**
 * Hook to manage multiple Form.Lists with statuses such as created, updated, and deleted.
 *
 * @param form - The Ant Design form instance.
 * @returns {object} - Object containing helper functions for managing multiple lists.
 */
const useFormListsManager = (form) => {
    // Add a new item with the status 'created'
    const addItem = (listName, add) => {
        add({operate: 1 }); // 使用 add 函数添加新项，并标记为 'created'
    };

    // Remove an item. If it has an ID, mark it as 'deleted', otherwise remove it completely
    const customRemove = (listName, remove, name, index) => {
        const currentItem = form.getFieldValue([listName, index]);
        if (currentItem && currentItem.id) {
            form.setFieldsValue({
                [listName]: form.getFieldValue(listName).map((item, idx) =>
                    idx === index ? { ...item, operate: 3 } : item
                ),
            });
        } else {
            remove(name);
        }
    };

    // Handle when an item is modified, mark it as 'updated'
    const handleFieldChange = ( changedFields, allValues) => {
        console.log("Changed Fields:", changedFields);

        // 遍历 changedFields 对象中的每个键值对
        Object.keys(changedFields).forEach((listName) => {
            const changedItems = changedFields[listName]; // 获取变化的列表项
            if (Array.isArray(changedItems)) {
                // 遍历发生变化的列表中的每个项
                changedItems.forEach((changedItem, index) => {
                    if (changedItem) {
                        console.log(`Form List: ${listName} changed at index ${index}`, changedItem);

                        const currentItem = allValues[listName][index];
                        if (currentItem && currentItem.id && currentItem.status !== 'deleted') {
                            // 如果是已存在的项，并且没有被标记为删除，将状态设置为 'updated'
                            form.setFieldsValue({
                                [listName]: allValues[listName].map((item, idx) =>
                                    idx === index ? { ...item, operate: 2 } : item
                                ),
                            });
                        }
                    }
                });
            }
        });
    };

    // Use effect to watch changes to form values for the lists and update status accordingly

    return {
        addItem,
        customRemove,
        handleFieldChange
    };
};

export default useFormListsManager;
