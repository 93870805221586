import React, {createContext, useContext, useEffect, useState} from 'react';
import {Steps} from 'antd';
import './index.scss';
import EduEmployStepForm from "@/components/quickfilingComp/dashboardProfile/eduEmployForm";
import ResearchAwards from "@/components/quickfilingComp/dashboardProfile/researchAwards";
import {MainStepContext} from "@/components/quickfilingComp/mainSteps";
import Book_patent_funding from "@/components/quickfilingComp/dashboardProfile/book_patent_funding";
import Honor_media_present from "@/components/quickfilingComp/dashboardProfile/honor_media_present";
import ProfessionalExperiences from "@/components/quickfilingComp/dashboardProfile/professionalExps";
import IndustryAdoption from "@/components/quickfilingComp/dashboardProfile/IndustryAdotion";
import PersonalInfoStepForm from "@/components/quickfilingComp/dashboardProfile/personalInfo";
import {useDispatch} from "react-redux";



const {Step} = Steps;

export const StepContext = createContext();


export const moveItem = (form, index, direction, fields, tableName) => {
    if ((index === 0 && direction === -1) || (index === fields.length - 1 && direction === 1)) {
        return; // Prevent moving out of bounds
    }
    // 获取当前的表单值
    const currentValues = form.getFieldValue(tableName);
    // 交换当前索引与目标索引的值
    const temp = currentValues[index];
    currentValues[index] = currentValues[index + direction];
    currentValues[index + direction] = temp;
    // 重新设置表单值
    form.setFieldsValue({
        [tableName]: currentValues,
    });
};

const getSteps = () => {

    return [
        {
            title: 'Personal Information',
            key: 'personalInformation',
            content: (
                <PersonalInfoStepForm />
            ),
        },
        {
            title: 'Education & Employment',
            key: 'eduEmployHistory',
            content:
                <EduEmployStepForm />
        },
        {
            title: 'Research & Publication',
            key: 'researchAndAwards',
            content: <ResearchAwards />
        },
        {
            title: 'Books & Patents & Funding',
            key: 'booksPatentsFunding',
            content: <Book_patent_funding />
        },
        {
            title: 'Honors & MediaReport & Presentations',
            key: 'honorsMediaReport',
            content: <Honor_media_present />
        },
        {
            title: 'professionalExperiences',
            key: 'externalRecognition',
            content: <ProfessionalExperiences/>
        },
        {
            title: 'IndustryAdoption',
            key: 'IndustryAdoption',
            content: <IndustryAdoption/>
        },

    ];

}

const ProfileStepForm = () => {

    const [current, setCurrent] = useState(0);
    const {setMainCurrent} = useContext(MainStepContext)
    const dispatch = useDispatch()
    const steps = getSteps();

    // 从服务端加载数据，保存到redux中
    useEffect(() => {
        setMainCurrent(0)
    }, [current]);


    return (
        <div className="step-form-container">
            <div className="progress-bar">
                <Steps direction="vertical" current={current}>
                    {steps.map((item, index) => (
                        <Step
                            key={index}
                            title={item.title}
                            className={current >= index ? 'step-completed' : 'step-pending'}
                        />
                    ))}
                </Steps>
            </div>
            <div className="form-content">
                <StepContext.Provider value={{current, setCurrent}}>
                    <div>{steps[current].content}</div>
                </StepContext.Provider>
            </div>
        </div>
    );
};

export default ProfileStepForm;



// const debouncedSaveCurrentStepData = debounce(async () => {
//     try {
//         await form.validateFields();
//         const values = form.getFieldsValue(true);
//         console.log('debouncedSaveCurrentStepData---', values)
//         const savedData = JSON.parse(localStorageGetQuickFilingForm()) || {};
//         for (const key in values) {
//             if (Array.isArray(values[key])) {
//                 const filteredList = values[key].filter(
//                     item =>
//                         item &&
//                         typeof item === 'object' &&
//                         Object.values(item).some(value => value !== null && value !== undefined && value !== '')
//                 );
//                 if (filteredList.length > 0) {
//                     savedData[key] = filteredList;
//                 }
//             } else {
//                 if (values[key] !== null && values[key] !== undefined && values[key] !== '') {
//                     savedData[key] = values[key];
//                 }
//             }
//         }
//         localStorageSetQuickFilingForm(JSON.stringify(savedData));
//     } catch (error) {
//         console.log('Validation Failed:', error);
//     }
// }, 500); // 500 毫秒防抖

//  const navigate = useNavigate()
// const handleSubmit = async () => {
//     //todo use localstorage or form.getFields
//     await debouncedSaveCurrentStepData()
//     const savedData = JSON.parse(localStorageGetQuickFilingForm()) || {};
//     console.log('Final data:', savedData); // 可以在此查看所有步骤的数据
//     navigate('/quickfiling');
// };



// const getQuickFilingDataFromLocalStorage = () => {
//
//     const savedData = JSON.parse(localStorageGetQuickFilingForm()) || {};
//     // 将需要转换的日期字段逐一进行处理
//     if (savedData) {
//         if (savedData.education) {
//             savedData.education.forEach(item => {
//                 if (item.graduationYear) {
//                     item.graduationYear = moment(item.graduationYear); // 转换为 moment 对象
//                 }
//             });
//         }
//         if (savedData.fundingAward) {
//             savedData.fundingAward.forEach(item => {
//                 if (item.awardDate) {
//                     item.awardDate = moment(item.awardDate);
//                 }
//             });
//         }
//
//         if (savedData.honors) {
//             savedData.honors.forEach(item => {
//                 if (item.receiveDate) {
//                     item.receiveDate = moment(item.receiveDate);
//                 }
//             });
//         }
//
//         if (savedData.presentations) {
//             savedData.presentations.forEach(item => {
//                 if (item.receiveDate) {
//                     item.receiveDate = moment(item.receiveDate);
//                 }
//             });
//         }
//
//         if (savedData.professionalMembership) {
//             savedData.professionalMembership.forEach(item => {
//                 if (item.dateOfJoining) {
//                     item.dateOfJoining = moment(item.dateOfJoining);
//                 }
//             });
//         }
//
//         if (savedData.awardFundingReviewerExperience) {
//             savedData.awardFundingReviewerExperience.forEach(item => {
//                 if (item.year) {
//                     item.year = moment(item.year);
//                 }
//             });
//         }
//     }
//
//     return savedData;
// }
