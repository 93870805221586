import React from 'react';
import { Button, DatePicker, Form } from 'antd';
import dayjs from 'dayjs';
const dateTimestamp = dayjs('2024-01-01').format('YYYY-MM-DD');
const onFinish = (values) => {
    console.log('Success:', values);
};
const App = () => (
    <Form
        name="getValueProps"
        labelCol={{
            span: 8,
        }}
        wrapperCol={{
            span: 16,
        }}
        style={{
            maxWidth: 600,
        }}
        initialValues={{
            date: dateTimestamp,
        }}
        onFinish={onFinish}
        autoComplete="off"
    >
        <Form.Item
            label="Date"
            name="date"
            rules={[
                {
                    required: true,
                },
            ]}
            // getValueProps={(value) => ({
            //     value: value && dayjs(Number(value)),
            // })}
            // normalize={(value) => value && `${dayjs(value).valueOf()}`}
            getValueProps={(value) => ({
                value: value && dayjs(value, 'YYYY-MM-DD'),
            })}
            normalize={(value) => value && dayjs(value).format('YYYY-MM-DD')}
        >
            <DatePicker />
        </Form.Item>

        <Form.Item
            wrapperCol={{
                offset: 8,
                span: 16,
            }}
        >
            <Button type="primary" htmlType="submit">
                Submit
            </Button>
        </Form.Item>
    </Form>
);
export default App;