import React, {useContext, useEffect} from "react";
import UploadFileBox from "@/components/common/dragUpload";
import {Button, Col, Divider, Form, Input, Row, Select, Typography} from "antd";
import {StepContext} from "@/components/quickfilingComp/dashboardProfile/profileForm"
import {useDispatch, useSelector} from "react-redux";
import {setBasicInfo} from "@/store/modules/quick";

const {Title} = Typography;

const PersonalInfoStepForm = () => {

    const {current, setCurrent} = useContext(StepContext)
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const quickForm = useSelector(state => state.quickForm.quickFilingForm);

    const onFinishIntro = async () => {
        try {
            const values = await form.validateFields();
            // 将合并后的数据写入 Redux
            dispatch(setBasicInfo(values.basicInfo));
            setCurrent(current + 1);
        }catch(error) {
            console.error('Validation Failed:', error);
        }
    }

    useEffect(()=>{
        form.setFieldsValue({basicInfo : quickForm.basicInfo});
    }, [quickForm])




    return (
        <div>
            <div>
                <Title level={3}>Intro</Title>
            </div>
            <Divider/>
            <div style={{marginBottom: 30}}>
                <UploadFileBox form={form} fieldName={'basicInfo'} label={'resume'}/>
            </div>
            <Form form={form}
                  layout="vertical"
                  >
                <Form.Item>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label="Title" name={['basicInfo', 'title']}>
                                <Select>
                                    <Select.Option value="mr">Mr</Select.Option>
                                    <Select.Option value="mrs">Mrs</Select.Option>
                                    <Select.Option value="miss">Miss</Select.Option>
                                    <Select.Option value="dr">Dr</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={['basicInfo', 'firstname']}
                                label="First Name"
                                rules={[{required: true, message: '请输入姓名'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={['basicInfo', 'middleName']}
                                label="Middle Name"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={['basicInfo', 'lastname']}
                                label="Last Name"
                                rules={[{required: true, message: '请输入姓名'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={['basicInfo', 'suffix']}
                                label="Suffix"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Gender" name={['basicInfo', 'gender']}>
                                <Select>
                                    <Select.Option value="0">Male</Select.Option>
                                    <Select.Option value="1">Female</Select.Option>
                                    <Select.Option value="2">Other</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
            <Button type={"primary"} onClick={onFinishIntro}>Next</Button>
        </div>
    )

}

export default PersonalInfoStepForm;