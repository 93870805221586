import {
    Button,
    Divider,
    Drawer,
    Form,
    Input,
    Select,
    Typography
} from "antd";
import {Field} from "@/data/data";
import React, {useContext, useEffect, useState} from "react";

import {StepContext} from "@/components/quickfilingComp/dashboardProfile/profileForm";
import "./index.scss"

import ScholarPapersTable from "@/components/quickfilingComp/tables/scholarPapersTable";
import {
    setHighlightComments,
    setMissingCitations,
    setResearch,
    setUnlistedArticles
} from "@/store/modules/quick";
import {useDispatch, useSelector} from "react-redux";


const {Title} = Typography;

const ResearchAwards = () => {

    const [open, setOpen] = useState(false);
    const [activeFormListIndex, setActiveFormListIndex] = useState(null);  // 当前激活的 form list
    const [activeFormType, setActiveFormType] = useState(null);  // 区分哪个 Form List 触发 Drawer
    const [inputFieldName, setInputFieldName] = useState(null);  // 用于区分是哪个 input 被点击
    const {current, setCurrent} = useContext(StepContext)
    const [form] = Form.useForm();
    const dispatch = useDispatch()
    const quickFormData = useSelector(state => state.quickForm.quickFilingForm);

    useEffect(() => {
        form.setFieldsValue({
            research : quickFormData.research || {},
            unlistedArticles: quickFormData.unlistedArticles || [],
            missingCitations: quickFormData.missingCitations || [],
            highlightComments: quickFormData.highlightComments || [],
        });
    }, [])


    const onFinish = async () => {
        await setForm2Redux()
        setCurrent(current + 1);
    }
    const showDrawer = (formListIndex, formType, inputField) => {
        setActiveFormListIndex(formListIndex);  // 记录当前点击的 form list
        setActiveFormType(formType);  // 记录点击的是哪个 form list
        setInputFieldName(inputField);  // 记录点击的是哪个 input
        setOpen(true);  // 打开 Drawer
        const research = form.getFieldsValue(['research'])
    };

    const setForm2Redux = async () => {
        try {
            // 验证表单数据
            const values = await form.validateFields();
            // 保存到 Redux
            dispatch(setResearch(values.research));
            dispatch(setUnlistedArticles(values.unlistedArticles));
            dispatch(setMissingCitations(values.missingCitations));
            dispatch(setHighlightComments(values.highlightComments));
        } catch (error) {
            console.error('Validation Failed:', error);
        }
    }

    const closeDrawer = () => {
        setOpen(false);
        setActiveFormListIndex(null);  // 重置 activeFormListIndex
        setActiveFormType(null);  // 重置 formType
        setInputFieldName(null);  // 重置 inputField
    };


    const handleSelect = (selectedKey) => {
        // 将选中的条目填充到表单的对应 Input 框中，使用 inputFieldName 确定是哪一个 input 框
        form.setFieldValue([activeFormType, activeFormListIndex, inputFieldName], selectedKey[0]);
        closeDrawer();  // 关闭 Drawer
    };


    const prev = async () => {
        await setForm2Redux()
        setCurrent(current - 1);
    }


    return (
        <div className={'eduEmployForm'}>
            <Form form={form} layout="vertical" >
                <div>
                    <Title level={3}>Research & Awards</Title>
                </div>
                <Divider/>
                <Form.Item>
                    <Form.Item name={['research', 'field']} label='Research Field'>
                        <Select>
                            {Field.map((item, index) => (
                                <Select.Option key={index} value={item.value}>{item.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name={['research', 'interest']} label='Research Interest'>
                        <Input.TextArea rows={5}></Input.TextArea>
                    </Form.Item>
                    <Form.Item name={['research', 'scholarURL']} label='Google Scholar Url'>
                        <Input />
                    </Form.Item>
                </Form.Item>
            </Form>
            <Button style={{margin: '0 8px'}} onClick={prev}>Prev</Button>
            <Button type={"primary"} onClick={onFinish}>Next</Button>
            <Drawer
                destroyOnClose={true}
                placement={'bottom'}
                closable={false}
                onClose={closeDrawer}
                open={open}
                height={600}
            >

                <ScholarPapersTable research={form.getFieldsValue(['research']).research} handleSelect={handleSelect}/>
            </Drawer>

        </div>
    )
}

export default ResearchAwards;


// let addUnlistedArticles;
// let addMissingCitation;
// let addHighlightComments;

/*
*
* <Title level={4}>Unlisted Articles</Title>
                <p>Articles Not Indexed on Google Scholar</p>
                <br/>
                <Form.List name="unlistedArticles">
                    {(fields, {add, remove}) => {
                        addUnlistedArticles = add
                        const customRemove = (name, index) => {
                            remove(name);
                            removeCurrentFormItem("unlistedArticles", index)
                        }
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => {
                                    return (
                                        <div className={'edu-history'} key={index}>
                                            <Title level={5}>*UnlistedArticles-{index + 1}</Title>
                                            <Space align={'start'}>
                                                <div>
                                                    <div className={'edu-history-item'}>
                                                        <Row>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'title']}
                                                                    label='Title'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'journal']}
                                                                    label='Journal Name'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'status']}
                                                                    label='status'
                                                                >
                                                                    <Select>
                                                                        <Option value="0">accepted</Option>
                                                                        <Option value="1">under review</Option>
                                                                        <Option value="2">published</Option>
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Proof</Title>
                                                    <UploadFileBox form={form} fieldName={"unlistedArticles"}
                                                                   index={index} label={"unlisted_article_proof"}/>
                                                    <br/>
                                                    <Title level={5}>Article Draft</Title>
                                                    <UploadFileBox form={form} fieldName={"unlistedArticles"}
                                                                   index={index} label={"draft_proof"}/>
                                                </div>
                                                <Space direction={"vertical"}>
                                                    {fields.length > 1 && <Space direction={"vertical"}>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, -1, fields, 'unlistedArticles')}>Up</Button>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, 1, fields, 'unlistedArticles')}>Down</Button>
                                                    </Space>}
                                                    <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                                </Space>
                                            </Space>
                                        </div>
                                    )
                                })}

                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                    <Form.Item>
                        <Button
                            onClick={() => addUnlistedArticles()}
                            icon={<PlusOutlined/>}
                        >
                            Add Unlisted Articles
                        </Button>
                    </Form.Item>
                </div>
                <Divider/>
                <Title level={4}>Upload Missing Citation</Title>
                <br/>
                <Form.List name="missingCitations">
                    {(fields, {add, remove}) => {
                        addMissingCitation = add
                        const customRemove = (name, index) => {
                            remove(name);
                            removeCurrentFormItem("missingCitations", index)
                        }
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => {
                                    return (
                                        <div className={'edu-history'} key={index}>
                                            <Title level={5}>*Missing Citation-{index + 1}</Title>
                                            <Space align={'start'}>
                                                <div>
                                                    <div className={'edu-history-item'}>
                                                        <Row gutter={16}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'citedArticle']}
                                                                    label='Cited Article'
                                                                >
                                                                    <Input readOnly
                                                                           placeholder="Select Article"
                                                                           onClick={() => showDrawer(index, 'missingCitations', 'citedArticle')}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'title']}
                                                                    label='Title'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Paper Pdf</Title>
                                                    <UploadFileBox form={form} fieldName={"missingCitations"}
                                                                   index={index} label={"miss_citation_proof"}/>
                                                </div>
                                                <Space direction={"vertical"}>
                                                    {fields.length > 1 && <Space direction={"vertical"}>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, -1, fields, 'missingCitations')}>Up</Button>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, 1, fields, 'missingCitations')}>Down</Button>
                                                    </Space>}
                                                    <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                                </Space>
                                            </Space>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                    <Form.Item>
                        <Button
                            onClick={() => addMissingCitation()}
                            icon={<PlusOutlined/>}
                        >
                            Add Missing Citation
                        </Button>
                    </Form.Item>
                </div>
                <Divider/>
                <Title level={4}>Highlighted Comments from Reviewers of Your Publications</Title>
                <p>Outstanding Reviewer Recognition</p>
                <br/>
                <Form.List name="highlightComments">
                    {(fields, {add, remove}) => {
                        addHighlightComments = add
                        const customRemove = (name, index) => {
                            remove(name);
                            removeCurrentFormItem("highlightComments", index)
                        }
                        return (
                            <>
                                {fields.map(({key, name, ...restField}, index) => {
                                    return (
                                        <div className={'edu-history'} key={index}>
                                            <Title level={5}>*Highlighted Comments-{index + 1}</Title>
                                            <Space align={'start'}>
                                                <div>
                                                    <div className={'edu-history-item'}>
                                                        <Row gutter={16}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'citedArticle']}
                                                                    label='Cited Article'
                                                                >
                                                                    <Input readOnly
                                                                           placeholder="Select Article"
                                                                           onClick={() => showDrawer(index, 'highlightComments', 'citedArticle')}/>
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...restField}
                                                                    name={[name, 'comment']}
                                                                    label='Comments'
                                                                >
                                                                    <Input/>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Title level={5}>Proof</Title>
                                                    <UploadFileBox form={form} fieldName={"highlightComments"}
                                                                   index={index} label={"highlight_proof"}/>
                                                </div>
                                                <Space direction={"vertical"}>
                                                    {fields.length > 1 && <Space direction={"vertical"}>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, -1, fields, 'highlightComments')}>Up</Button>
                                                        <Button type="dashed"
                                                                onClick={() => moveItem(form, index, 1, fields, 'highlightComments')}>Down</Button>
                                                    </Space>}
                                                    <Button onClick={() => customRemove(name, index)}>Remove</Button>
                                                </Space>
                                            </Space>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    }}
                </Form.List>
                <div style={{marginTop: 10}}>
                    <Form.Item>
                        <Button
                            onClick={() => addHighlightComments()}
                            icon={<PlusOutlined/>}
                        >
                            Add Highlighted Comments
                        </Button>
                    </Form.Item>
                </div>
                <Divider/>
*
* */