import React, {useState, useEffect, useRef} from 'react';
import {Input, Avatar, Typography, Row, Col, Button, Spin} from 'antd';
import {askChatGPTApi} from '@/apis/chat';
import "./index.scss"
import { SendOutlined } from "@ant-design/icons";
import bot_png from "@/assets/avatar/chatbot.png"
import user_png from "@/assets/avatar/chat-user.png"
import useDefaultLanguage  from '@/customHooks/language'


const { TextArea } = Input;

const { Paragraph} = Typography;

const ChatBox = () => {
    const [messages, setMessages] = useState([{
        content: `Hello! I'm here to assist you with your questions regarding employment-based immigration. How can I help you today?`,
        role: "assistant",
    }]);
    const defaultLanguage = useDefaultLanguage();
    const [isLoading, setIsLoading] = useState(false); // 用于跟踪是否正在加载
    const [isComposing, setIsComposing] = useState(false); // 用于检测是否处于组合输入状态

    const messagesEndRef = useRef(null);
    const [inputValue, setInputValue] = useState('');

    // 检测是否包含中文字符
    const detectLanguage = (inputText) => {
        const chineseRegex = /[\u4e00-\u9fff]/;
        return chineseRegex.test(inputText) ? 'zh' : 'en';
    };

    // 处理输入框按键
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey && !isComposing) {
            e.preventDefault(); // 阻止默认的换行行为
            handleSend(); // 调用发送消息函数
        }
    };

    const handleSend = () => {

        if (inputValue.trim()) {
            const language = detectLanguage(inputValue) || defaultLanguage;
            setMessages([...messages, {role: 'user', content: inputValue}]);
            setIsLoading(true);  // 发送消息后设置加载状态为true
            setInputValue('');
            const data = {
                content: inputValue,
                language : language,
            }
            askChatGPTApi(data).then(res => {
                let respMessage ;
                if (res.code !== 0 ) {
                    respMessage = {
                        "role": "assistant",
                        "content": "sorry, I can not understand you, please input again",
                    }
                }else {
                    respMessage = {
                        "role": res.data.role,
                        "content": res.data.content,
                    }
                }
                setMessages((prevMessages) => [...prevMessages, respMessage]);
                setIsLoading(false);  // 回复收到后取消加载状态
            }).catch(err => {
                console.log("gpt err: ", err)
            })
        }
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({behavior: 'smooth'});
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <div className={'flexCenter'}>
            <div className={'chat-content'}>
                {messages.map((message, index) => (
                    <div key={index} style={{width: '100%', marginBottom: 30}}>
                        <Row align={"top"}>
                            <Col span={2}>
                                <Avatar src={message.role === "assistant" ? bot_png : user_png} size={50}/>
                            </Col>
                            <Col span={22}>
                                <div className={message.role === "assistant" ? '' : 'chat-item'}>
                                    <Paragraph style={{fontSize: 18}}>
                                        {message.content}
                                    </Paragraph>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))}
                {isLoading && (
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <Spin />
                    </div>
                )}
                <div ref={messagesEndRef}/>
            </div>
            <div className={'chat-input'}>
                <div className={'chat-input-item'}>
                    <div>
                        <Row align="middle" justify='space-between'>
                            <Col span={21}>
                                <TextArea
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    placeholder="Type your message..."
                                    autoSize={{minRows: 1, maxRows: 3}}
                                    size={'large'}
                                    style={{
                                        fontSize: '16px',
                                        padding: '10px',
                                    }}
                                    variant={'borderless'}
                                    onKeyDown={handleKeyDown} // 监听键盘事件
                                    onCompositionStart={() => setIsComposing(true)} // 开始组合输入
                                    onCompositionEnd={() => setIsComposing(false)}  // 结束组合输入

                                />
                            </Col>
                            <Col span={2}>
                                <Button icon={<SendOutlined style={{fontSize: 24}}/>}
                                        type={"text"}
                                        onClick={handleSend}
                                        disabled={isLoading || inputValue === ''}
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ChatBox;